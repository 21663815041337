/** @format */

import React, { createContext, useEffect, useState } from 'react';

import { SENDER_HUB_API } from '../../../links';
import log from '../../util/logger';

import type { LoggedInUser } from '../../../types/loggedInUser';
import type { FunctionComponent, ReactElement, PropsWithChildren } from 'react';

interface UserLoginStore {
    loggedInUser: LoggedInUser | null;
    setLoggedInUser: React.Dispatch<React.SetStateAction<null>> | null;
    loadingLogin: boolean;
}

export const UserContext = createContext<UserLoginStore>({} as UserLoginStore);

export const UserContextProvider: FunctionComponent<PropsWithChildren> = ({
    children
}: PropsWithChildren): ReactElement => {
    const [loadingLogin, setLoadingLogin] = useState(true);
    const [loggedInUser, setLoggedInUser] = useState<LoggedInUser | null>(null);

    useEffect(() => {
        const fetchUserInfo = async () => {
            const response = await fetch(SENDER_HUB_API.USER_INFO);
            if (response.status === 200) {
                const data = await response.json() as LoggedInUser;
                setLoggedInUser(data);
            } else if (response.status === 204) {
                log.debug('User is not logged in');
            } else {
                throw new Error('Network response was not ok.');
            }
        };

        fetchUserInfo()
            .catch((error) => {
                log.error('Error fetching user info:', error);
            })
            .finally(() => {
                setLoadingLogin(false);
            });
    }, []);

    return (
        <UserContext.Provider value={{ loggedInUser, setLoggedInUser, loadingLogin }}>
            {children}
        </UserContext.Provider>
    );
};
