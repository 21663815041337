/** @format */

import { Endpoints } from 'sender-hub-shared';

import { getLink, getOutboundLink } from './components/links/linkUtil';

export const AMP_PAGE = '/amp';
export const BIMI_PAGE = '/bimi';
export const PROMOTIONS_AND_SCHEMA_PAGE = '/promotions-and-schema';
export const SUBHUB_PAGE = '/subhub';

export const BEST_PRACTICES_PAGE = '/best-practices';
export const DELIVERABILITY_FAQS_PAGE = '/faqs';
export const SMTP_ERROR_CODES_PAGE = '/smtp-error-codes';
export const OUTBOUND_MAIL_SERVERS_PAGE = '/outbound-mail-servers';
export const MAIL_PROXY_SERVERS_PAGE = '/mail-proxy-servers';
export const EMAIL_DELIVERABILITY_PERFORMANCE_FEEDS_PAGE = '/email-deliverability-performance-feeds';

export const CONTACT_PAGE = '/contact';
export const EVENT_PAGE = '/event';
export const COMPLAINT_FEEDBACK_LOOP_ANCHOR = 'complaint-feedback-loop';
export const COMPLAINT_FEEDBACK_LOOP_MODAL = `/contact#${COMPLAINT_FEEDBACK_LOOP_ANCHOR}`;
export const COMPLAINT_FEEDBACK_LOOP_PAGE = '/complaint-feedback-loop';
export const COMPLAINT_FEEDBACK_LOOP_FORM = 'https://io.help.yahoo.com/contact/index?page=contactform&locale=en_US&token=Zh%2FBBVqXzLHlIbokbUqVWTUbuuQeXGkGnZzhKR2JQ4O6mMQdy9JSWdtWFXvjthcYCRj9bUIFfycOfG%2B4GOHPHoOGa8HwDO2%2B0kYRtTcdR8Nja5P9HWkKh3VWfS3pyu4UdjhvwG%2BBCvnYFl5dToDK%2Fw%3D%3D&selectedChannel=email-icon';
export const CFL_ENROLL_NOW_LINK =
    'https://io.help.yahoo.com/contact/index?page=contactform&locale=en_US&token=Zh%2FBBVqXzLHlIbokbUqVWTUbuuQeXGkGnZzhKR2JQ4O6mMQdy9JSWdtWFXvjthcYCRj9bUIFfycOfG%2B4GOHPHoOGa8HwDO2%2B0kYRtTcdR8Nja5P9HWkKh3VWfS3pyu4UdjhvwG%2BBCvnYFl5dToDK%2Fw%3D%3D&selectedChannel=email-icon';
export const SENDER_SUPPORT_REQUEST_ANCHOR = 'sender-support-request';
export const SENDER_SUPPORT_REQUEST_PAGE = `/contact#${SENDER_SUPPORT_REQUEST_ANCHOR}`;
export const REPORT_ABUSE_ANCHOR = 'report-abuse';
export const REPORT_ABUSE_PAGE = `/contact#${REPORT_ABUSE_ANCHOR}`;

export const DEVELOPER_ACCESS_PAGE = '/developer/developer-access';
export const HOW_TO_REQUEST_ACCESS_ANCHOR = 'how-to-request-access';
export const HOW_TO_REQUEST_ACCESS = `${DEVELOPER_ACCESS_PAGE}#${HOW_TO_REQUEST_ACCESS_ANCHOR}`;
export const DEVELOPER_ACCESS_MAIL_FORM_PAGE = '/developer/developer-access-mail-form';
export const DOCUMENTATION_PAGE = '/developer/documentation';

export const YAHOO_MAIL_BLOG = 'https://www.yahooinc.com/press';
export const SUPPORT_MAIL_TO = 'mailto:mail-questions@yahooinc.com';
export const DEVELOPER_SUPPORT_MAIL_TO = 'mailto:mail-api@yahooinc.com';
export const POSTMASTER = 'https://postmaster.yahooinc.com';
export const POSTMASTER_BLOG = 'https://blog.postmaster.yahooinc.com';
export const LEARN_ABOUT_OAUTH2 = 'https://developer.yahoo.com/oauth2/guide';
export const YAHOO_EVENT_CONTACT_EMAIL = 'mailto:sendersummit@yahooinc.com';
export const YAHOO_SENDER_HUB_FEEDBACK_EMAIL = 'mailto:senderhub-feedback@yahooinc.com';

export const DELIVERABILITY_LINKS = {
    canSpam: (displayText?: string) => {
        return getOutboundLink(
            'https://www.ftc.gov/business-guidance/resources/can-spam-act-compliance-guide-business',
            'CAN-SPAM Act',
            displayText
        );
    },
    cfl: (displayText?: string) => {
        return getLink(COMPLAINT_FEEDBACK_LOOP_PAGE, 'CFL', displayText);
    },
    dkim: (displayText?: string) => {
        return getOutboundLink('http://www.dkim.org', 'DKIM', displayText);
    },
    dmarc: (displayText?: string) => {
        return getOutboundLink('https://dmarc.org', 'DMARC', displayText);
    },
    faqs: (displayText?: string) => {
        return getLink(DELIVERABILITY_FAQS_PAGE, 'FAQs', displayText);
    },
    ietfRfc: (rfcNumber: number, displayText?: string) => {
        return getOutboundLink(`https://tools.ietf.org/html/rfc${rfcNumber}`, `RFC ${rfcNumber}`, displayText);
    },
    reportAbuse: (displayText?: string) => {
        return getLink(REPORT_ABUSE_PAGE, 'Report Abouse', displayText);
    },
    senderBestPractices: (displayText?: string) => {
        return getLink(BEST_PRACTICES_PAGE, 'Sender Requirements & Recommendations', displayText);
    },
    senderSupportRequest: (displayText?: string) => {
        return getLink(SENDER_SUPPORT_REQUEST_PAGE, 'Sender Support Request', displayText);
    },
    spf: (displayText?: string) => {
        return getOutboundLink('http://www.open-spf.org', 'SPF', displayText);
    },
    supportMailTo: (displayText?: string) => {
        return getOutboundLink(SUPPORT_MAIL_TO, 'Mail to mail-questions@yahooinc.com', displayText);
    },
    wiki: (wikiArticle: string, title: string, displayText?: string) => {
        return getOutboundLink(`https://en.wikipedia.org/wiki/${wikiArticle}`, title, displayText);
    }
};

export const FAQ_DKIM_ID = 'dkim';
export const FAQ_CFL_ID = 'cfl';

export const CFL_LINKS = {
    dkim: (displayText?: string) => {
        return getLink(`${DELIVERABILITY_FAQS_PAGE}#${FAQ_DKIM_ID}`, 'DKIM', displayText);
    },
    arf: (displayText?: string) => {
        return getLink(`${DELIVERABILITY_FAQS_PAGE}#${FAQ_CFL_ID}`, 'CFL Format', displayText);
    }
};


export const SETTINGS_YAHOO_PERSONAL_INFO = 'https://login.yahoo.com/myaccount/personalinfo';


/* region Feature Management */
export const DOMAIN_MANAGEMENT_PAGE = '/feature-management/account-overview#domains';
/* endregion */

export const FEATURE_MANAGEMENT_PREFIX = '/feature-management';
export const FEATURE_MANAGEMENT_PAGES = {
    ACCOUNT_OVERVIEW: `${FEATURE_MANAGEMENT_PREFIX}/account-overview`,
    DOMAIN_MANAGEMENT: `${FEATURE_MANAGEMENT_PREFIX}/account-overview#domains`,
    DASHBOARD: `${FEATURE_MANAGEMENT_PREFIX}/dashboard`,
    SENDER_PROFILE: `${FEATURE_MANAGEMENT_PREFIX}/sender-profile`,
    SETTINGS: `${FEATURE_MANAGEMENT_PREFIX}/account-overview#settings`,
    SERVICE_MANAGEMENT: `${FEATURE_MANAGEMENT_PREFIX}/account-overview#services`,
    SERVICES: {
        CFL: `${FEATURE_MANAGEMENT_PREFIX}/services/cfl`
    }
};

export const SENDER_HUB_API: Record<string, string> = {
    CFL: `${Endpoints.V1_API}${Endpoints.CFL}`,
    CFL_UNENROLL: `${Endpoints.V1_API}${Endpoints.CFL}${Endpoints.UNENROLL}`,
    CFL_OTP_UPDATE: `${Endpoints.V1_API}${Endpoints.CFL}${Endpoints.UPDATE}`,
    DOMAINS: `${Endpoints.V1_API}${Endpoints.DOMAINS}`,
    DOMAINS_DELETE: `${Endpoints.V1_API}${Endpoints.DOMAINS_DELETE}`,
    TNC: `${Endpoints.V1_API}${Endpoints.TNC}`,
    SIGN_IN: `${Endpoints.V1_API}${Endpoints.LOGIN_PATH}${Endpoints.SIGN_IN}`,
    SIGN_UP: `${Endpoints.V1_API}${Endpoints.LOGIN_PATH}${Endpoints.SIGN_IN}?signup=1`,
    SIGN_OUT: `${Endpoints.V1_API}${Endpoints.LOGIN_PATH}${Endpoints.SIGN_OUT}`,
    USER: `${Endpoints.V1_API}${Endpoints.USER}`,
    USER_INFO: `${Endpoints.V1_API}${Endpoints.LOGIN_PATH}${Endpoints.GET_USER_INFO}`,
    WHATS_NEW: `${Endpoints.V1_API}${Endpoints.RSS}${Endpoints.WHATS_NEW}`
};

export const CSRF_HEADER = 'x-csrf-token';

export const SENDER_HUB_TERMS_AND_CONDITIONS = 'https://legal.yahoo.com/us/en/yahoo/terms/product-atos/sender-hub/index.html';

export const SENDER_HUB_PRIVACY_POLICY = 'https://legal.yahoo.com/us/en/yahoo/privacy/index.html';